import "../styles/style.css";
import "../styles/payout.css";
import { imageIcon, getIconPath } from "../data/imagePath";

import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export function SideMenuItems({ item, toggle }) {
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const handleClick = (value) => {
    setPath(value.path);
    if (value.path !== "" && value.path !== undefined) {
      navigate(value.path);
    } else {
      setOpen(!open);
    }
  };

  useEffect(() => {
    if (toggle && open) {
      setOpen(true);
    }
  }, [toggle]);

  useEffect(() => {
    if (item.children) {
      const isChildActive = item.children.some(
        (child) => child.path === location.pathname
      );
      setOpen(isChildActive);
    }
    if (open) {
      setOpen(!toggle);
    }
  }, [item.children, location.pathname, toggle]);

  const isActive = (itemPath) => {
    return location.pathname === itemPath;
  };
  const isParentRoute = (item) => {
    const currentPath = location.pathname;

    if (!item.children || !currentPath) {
      return false;
    }

    // Check if any child path matches or is a parent of the current path
    return item.children.some((child) => {
      const childPath = child.path;
      return (
        // Exact match
        currentPath === childPath ||
        // Current path is a parent of child path
        (currentPath.startsWith(childPath) &&
          currentPath[childPath.length] === "/")
      );
    });
  };

  const shouldAddToggleClass = toggle && isParentRoute(item);

  if (item.children) {
    return (
      <>
        <div className={open ? "sideMenu-item openActive" : "sideMenu-item "}>
          <div
            className={`${open ? "navActive" : ""} sideLink ${
              shouldAddToggleClass ? "activeParent" : ""
            }`}
            onClick={() => handleClick(item)}
          >
            <div className="sideLink_item">
              <img
                src={imageIcon[item.icon]}
                alt=""
                className="sidelink_icon"
              />
              {item.title}
            </div>
            <img
              // src={open ? imageIcon["arrowDown"] : imageIcon.sideRight}
              src={open ? imageIcon.sideRight : imageIcon.sideRight}
              alt=""
              className="sideArrow"
            />
          </div>
          <ul className="submenu">
            {item.children.map((item, index) => (
              <Link
                key={index}
                className={`submenu_item ${
                  isActive(item.path) ? "navActive" : ""
                }`}
                to={item.path}
              >
                <img
                  className="submenu_icon"
                  src={imageIcon[item.icon]}
                  alt=""
                />
                <div className="suLinknk">{item.title}</div>
              </Link>
            ))}
          </ul>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="sideMenu-item ">
          <div
            className={`${isActive(item.path) ? "navActive" : ""} sideLink`}
            onClick={() => handleClick(item)}
          >
            <div className="sideLink_item">
              <img
                src={imageIcon[item.icon]}
                alt=""
                className="sidelink_icon"
              />{" "}
              {item.title}
            </div>
            {/* <img src={imageIcon.sideRight} alt="" className="sideArrow" /> */}
          </div>
        </div>
      </>
    );
  }
}
