import { requestWithToken } from "../helper/helper";


const fetchBuyerRequestList = async (currentPage, perPage, status) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/buyerRequest/list/en?pageSize=${perPage}&pageNo=${currentPage}&status=${status}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createBuyerReuest = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/buyerRequest`, payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const reviceBuyerReuest = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/buyerRequest/revice`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const changeBidStatus = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/buyerRequest/bid/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchBuyerRequestList,
  createBuyerReuest,
  reviceBuyerReuest,
  changeBidStatus,
};
