import fstacLogoIcon from "../assets/images/fstag-logo.svg";
import homeIcon from "../assets/images/home.svg";
import sideRightIcon from "../assets/images/side_right.svg";
import codeIcon from "../assets/images/code.svg";
import payoutIcon from "../assets/images/payout.svg";
import dashboardIcon from "../assets/images/dashboard.svg";
import fileIcon from "../assets/images/file-code.svg";
import reportIcon from "../assets/images/report.svg";
import needHelpIcon from "../assets/images/need_help.svg";
import settingIcon from "../assets/images/settings.svg";
import logoutIcon from "../assets/images/log-out.svg";
import ArrowDownIcon from "../assets/images/arrowDownIcon.svg";
import onBoardingIcon from "../assets/images/onboarding_icon.svg";
import warehouse from "../assets/images/warehouse.svg";
import gaonIcon from "../assets/images/gaon.svg";
import userIcon from "../assets/images/users.svg";
import unitIcon from "../assets/images/unit.svg";
import productIcon from "../assets/images/product.svg";
import farmIcon from "../assets/images/farm.svg";

export const imageIcon = {
  code: codeIcon,
  payout: payoutIcon,
  dashboard: dashboardIcon,
  file: fileIcon,
  report: reportIcon,
  needHelp: needHelpIcon,
  setting: settingIcon,
  logout: logoutIcon,
  fstacLogo: fstacLogoIcon,
  home: homeIcon,
  sideRight: sideRightIcon,
  arrowDown: ArrowDownIcon,
  onBoarding: onBoardingIcon,
  warehouse: warehouse,
  gaon: gaonIcon,
  users: userIcon,
  units: unitIcon,
  products: productIcon,
  farm: farmIcon,
};

export const getIconPath = (iconName) => {
  return `../assets/images/${iconName}`; // Return the icon path or an empty string if not found
};
