import React, { useState, useEffect } from "react";

import editBtn from "../../assets/images/edit_btn.svg";

import plusWhite from "../../assets/images/plus-white.svg";
import { useFormik } from "formik";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import moment from "moment";
import { Backdrop } from "../backdrop";
import { fetchWarehouseBidList, acceptBid } from "../../action/warehouse";
import Button from "../../components/bootstrap/Button";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import {
  getGrades,
  getProducts,
  getunits,
  getVeriety,
} from "../../action/users";
import { createBuyerReuest } from "../../action/buyer";
import LocationSearchInput from "../../common/searchPlaces";
import Select from "../../components/bootstrap/forms/Select";
import Option from "../../components/bootstrap/Option";
import { handleKeyDown } from "../../helper/helper";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import showNotification from "../../components/extras/showNotification";

export const AddBuyerRequest = () => {
  const navigate = useNavigate();

  const [prodId, setProdId] = useState("");
  const [location, setLocation] = useState({});
  const [address, setAddress] = useState("");
  const [units, setUnits] = useState([]);
  const [products, setproducts] = useState([]);
  const [verietyList, setVerietyList] = useState([]);
  const [grades, setGrades] = useState([]);

  const fetchUnits = async () => {
    const response = await getunits();
    if (response.status) {
      setUnits(response.data);
    } else {
    }
  };

  const fetchProducts = async () => {
    const response = await getProducts();
    if (response.status) {
      setproducts(response.data);
    } else {
    }
  };

  const fetchVerieties = async () => {
    const response = await getVeriety(prodId);
    if (response.status) {
      setVerietyList(response.data);
    } else {
    }
  };

  const fetchGrades = async () => {
    const response = await getGrades();
    if (response.status) {
      setGrades(response.data);
    } else {
    }
  };

  useEffect(() => {
    fetchVerieties();
  }, [prodId]);

  useEffect(() => {
    fetchUnits();
    fetchProducts();
    fetchGrades();
  }, []);

  const addBidFormik = useFormik({
    initialValues: {
      productId: "",
      quantity: "",
      price: "",
      unitId: "",
      deliveryDate: "",
      location: {},
    },
    onSubmit: async (values, { resetForm }) => {
      values.location = location;
      console.log(values);
      const response = await createBuyerReuest(values);
      if (response.status) {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        addBidFormik.handleReset();
        navigate("/buyerReqest/list");
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const onCloseToaste = () => {
    toast.dismiss();
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            {/* <div className="payout_data_ttl">Create New Request</div> */}
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb
                paths={[
                  { name: "Buyer Requests", link: "/buyerReqest/list" },
                  { name: "Add", link: "/buyerReqest/add" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="add_buyer_content">
        <form
          action=""
          // className="payment_form"
          onSubmit={addBidFormik.handleSubmit}
        >
          <div className="row">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Delivery Address
                  <span>*</span>
                </div>
                <LocationSearchInput
                  address={address}
                  setAddress={setAddress}
                  setLocation={setLocation}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Price
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter price"
                    id="beneficiary"
                    name="price"
                    min="1"
                    max="100000000"
                    onChange={(e) => {
                      addBidFormik.setFieldValue("price", e.target.value);
                    }}
                    value={addBidFormik.values.price}
                    required
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Quantity
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter quantity"
                    id="beneficiary"
                    name="quantity"
                    min="1"
                    max="100000000"
                    onChange={(e) => {
                      addBidFormik.setFieldValue("quantity", e.target.value);
                    }}
                    value={addBidFormik.values.quantity}
                    required
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Unit
                  <span>*</span>
                </div>
                <div className="input_field">
                  <Select
                    className="input"
                    onChange={(e) => {
                      console.log(e.target.value, "  e");
                      addBidFormik.setFieldValue("unitId", e.target.value);
                    }}
                    value={addBidFormik.values.unitId ?? ""}
                    required
                  >
                    <Option value="">Select Unit</Option>
                    {units.map((unit) => {
                      return (
                        <Option key={unit._id} id={unit._id} value={unit._id}>
                          {unit.name.en}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Grade
                  <span>*</span>
                </div>
                <div className="input_field">
                  <Select
                    className="input"
                    onChange={(e) => {
                      addBidFormik.setFieldValue("gradeId", e.target.value);
                    }}
                    value={addBidFormik.values.gradeId ?? ""}
                    required
                  >
                    <Option value="">Select Grade</Option>
                    {grades.map((grade) => {
                      return (
                        <Option
                          key={grade._id}
                          id={grade._id}
                          value={grade._id}
                        >
                          {grade.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Product
                  <span>*</span>
                </div>
                <div className="input_field">
                  <Select
                    className="input"
                    onChange={(e) => {
                      console.log(e.target.value, "  e");
                      setProdId(e.target.value);
                      addBidFormik.setFieldValue("productId", e.target.value);
                    }}
                    required
                    value={addBidFormik.values.productId ?? ""}
                  >
                    <Option value="">Select Product</Option>
                    {products.map((unit) => {
                      return (
                        <Option key={unit._id} id={unit._id} value={unit._id}>
                          {unit.name.en}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">Variety</div>
                <div className="input_field">
                  <Select
                    className="input"
                    onChange={(e) => {
                      addBidFormik.setFieldValue("verietyId", e.target.value);
                    }}
                    value={addBidFormik.values.verietyId ?? ""}
                  >
                    <Option value="">Select Variety</Option>
                    {verietyList.map((veriety) => {
                      return (
                        <Option
                          key={veriety._id}
                          id={veriety._id}
                          value={veriety._id}
                        >
                          {veriety.name.en}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Delivery Date
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    required
                    className="input"
                    name="deliveryDate"
                    type="date"
                    placeholder="select date"
                    value={addBidFormik.values.deliveryDate}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={addBidFormik.handleChange}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </div>
              </div>
            </div>
            <div className="addBuyer_submit-btn addBuyer_submit-btn">
              <Button className="btn btn-success" type="submit">
                Save
              </Button>
              <Button
                color="dark"
                // isLight="btn btn-primary"
                tag="a"
                to="/buyerReqest/list"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
