import React, { useState, useEffect } from "react";
import plusWhite from "../../assets/images/plus-white.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import trashIcon from "../../assets/images/trash-03.svg";
import editIcon from "../../assets/images/edit-02.svg";

import Pagination from "../../customComponents/pagination/paginationView";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
import { getContestList } from "../../action/contest";
import moment from "moment";
import { Link } from "react-router-dom";

const ContestList = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalData, setTotalData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getCategoryPage = async (page) => {
    try {
      setIsLoading(true);
      const response = await getContestList(page, pageSize); // Adjust 10 to the desired number of items per page
      if (response.status) {
        setCategoryList(response.data.details);
        setTotalData(response.data.count);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  useEffect(() => {
    getCategoryPage(pageNo);
  }, [pageNo, pageSize]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="payout_data-content" style={{ height: '100%' }}>
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb paths={[{ name: "Contest" }]} />
            </div>
          </div>
          <Link className="btn" color="info" to={"/contest/add"}>
            Create New
            <img src={plusWhite} alt="" className="payout_down" />
          </Link>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <div className="scroll_v">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Title</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Options</div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="4">
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {categoryList?.length > 0 ? (
                        categoryList?.map((item) => (
                          <tr key={item._id}>
                            <td>{item?.title?.en}</td>
                            <td>{item?.options?.length}</td>
                            <td className="flex_btn">
                              <Link to={`/contest/edit/${item._id}`}>
                                <img src={editIcon} />
                              </Link>
                              <img src={trashIcon} />
                            </td>
                           
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </TabPanel>
        </Tabs>

        {categoryList.length > 0 && (
          <>
            <Pagination
              currentPage={pageNo}
              totalCount={totalData}
              pageSize={pageSize}
              onPageChange={(page) => setPageNo(page)}
              changePageSize={(page) => {
                setPageSize(page);
                setPageNo(1);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ContestList;
