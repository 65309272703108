import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import searchIcon from "../../assets/images/search-lg.svg";
import filerIcon from "../../assets/images/Up_Down.svg";
import Button from "../../components/bootstrap/Button";
import { fetchGaon } from "../../action/gaonApi";
import Pagination from "../../customComponents/pagination/paginationView";
import { TabPanel, Tabs } from "react-tabs";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
// import Breadcrumb from "../../components/bootstrap/Breadcrumb";

const Gaon = () => {
  const [gaonList, setGaonList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");

  const fetchGaonList = async (searchValue = search) => {
    setIsLoading(true);
    const response = await fetchGaon(
      pageNo,
      pageSize,
      searchValue,
      sort,
      order
    );
    if (response.status) {
      setGaonList(response.data.list);
      setTotalData(response.data.totalRecords);
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    const resetSearchValue = ""; // Reset search value
    setSearch("");
    fetchGaonList(resetSearchValue);
  };

  useEffect(() => {
    fetchGaonList();
  }, [pageNo, pageSize, sort, order]);

  return (
    <div className="payout_data-content tablePage_content_header" style={{ height: '100%' }}>
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb paths={[{ name: "Gaon", link: "/gaon" }]} />
            </div>
          </div>
        </div>
      </div>
      <div className="row search_box">
        <div className="col-lg-3">
          <div className="input-box mt-0">
            <div className="input-label">Search</div>
            <div className="input_field">
              <input
                type="text"
                className="input"
                placeholder="Type any keyword"
                id="beneficiary"
                name="search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <div className="build_search">
                <img src={searchIcon} alt="Search Icon" />
              </div>
            </div>
          </div>
          <div className="submit_btn_grp flex_btn mt-3">
            <button
              className="btn_submit btn btn-dark"
              type="button"
              onClick={() => {
                handleReset();
              }}
              disabled={!search}
            >
              Reset
            </button>
            <button
              className={`btn btn-success btn_submit ${search ? "active" : ""}`}
              type="submit"
              disabled={!search}
              onClick={() => {
                fetchGaonList();
              }}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <div className="scroll_v">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">
                        Name
                        <img
                          src={filerIcon}
                          onClick={() => {
                            setSort("name");
                            setOrder(order == 1 ? -1 : 1);
                          }}
                        />
                      </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Description</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Action</div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="4">
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {gaonList?.length > 0 ? (
                        gaonList?.map((item) => (
                          <tr key={item?._id}>
                            <td>{item?.name}</td>
                            <td>{item?.info ?? "-"}</td>
                            <td>
                              <div className="flex_btn">
                                <Link
                                  to={`/gaon/details/${item?._id}`}
                                  style={{ marginRight: "4px" }}
                                >
                                  <Button color="dark" isLight>
                                    View
                                  </Button>
                                </Link>
                                <Link to={`/gaon/edit/${item?._id}`}>
                                  <Button color="dark" isLight>
                                    Edit
                                  </Button>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">No data available</td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </TabPanel>
        </Tabs>
        <Pagination
          currentPage={pageNo}
          totalCount={totalData}
          pageSize={pageSize}
          onPageChange={(page) => setPageNo(page)}
          changePageSize={(page) => {
            setPageSize(page);
            setPageNo(1);
          }}
        />
      </div>
    </div>
  );
};

export default Gaon;
