import { requestWithToken } from "../helper/helper";

const fetchGaon = async (pageNo, pageSize, search, sort, order) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/gaonList?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}&sort=${sort}&order=${order}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchGaonDetails = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/gaonDetails/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editGaon = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/gaon/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export { fetchGaon, editGaon, fetchGaonDetails };
