import React from "react";
import Donut from "../../components/Graph/donutChart";
import BarChart from "../../components/Graph/barChart";

export const Dashboard = () => {
  return (
    <>
    <div className="payout_data-content mb-5">
      <div className="dashboard_head">
        <Donut />
      </div>
    </div>
    <div className="payout_data-content">
       <div className="dashboard_head">
        <BarChart />
       </div>
    </div>
    </>
  );
};
