import Modal from "@mui/material/Modal";
import uploadFile from "../assets/images/imgIcon.png";
import transArrow from "../assets/images/trans_arrow.svg";
import check_tick from "../assets/images/check_tick.svg";
import csvIcon from "../assets/images/csv.svg";
import ExportIcom from "../assets/images/exp_btn.svg";
import check_checked from "../assets/images/_Chec.svg";
import check_empt from "../assets/images/_Chec_base.svg";
import clndeIcon from "../assets/images/cld_icon.svg";
import "../styles/payout.css";
import { DateRangePicker } from "react-dates";
import { useState, useRef, useEffect } from "react";
import moment from "moment";
import { exportPayoutData } from "../action/payoutApi";
import {
  base64,
  downloadCSVFile,
  downloadFile,
  getDateRange,
  formatCurrentDate,
} from "../commonFunction/utils";
import toast from "react-hot-toast";
import { SingleErrorMessage } from "./singleErrorMessage";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import CircularProgress from "@mui/material/CircularProgress";

export const Export = ({ open, onClose }) => {
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectFileFormate, setSelectFileFormate] = useState("");
  const [showCalender, setShowCalender] = useState(false);
  const [selectDateRange, setSelectDateRange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const resetDates = () => {
    setDateState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };
  const currentDate = moment();
  const handleSelect = (value) => {
    if (value !== "Custom Date Range") {
      setSelectDateRange("");
      resetDates();
    }
    setSelected(value);
    if (value === "Custom Date Range") {
      setShowCalender(!showCalender);
      setSelectFileFormate("");
    }
  };

  const handleClose = () => {
    onClose();

    setSelected("");
    setSelectFileFormate("");
    resetDates();
    setSelectDateRange("");
    setShowCalender(false);
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const handleFileFormate = (value) => {
    setSelectFileFormate(value);
  };

  const select = useRef(null);

  const handleClickOutside = (event) => {
    if (select.current && !select.current.contains(event.target)) {
      setToggle(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const onCloseToaste = () => {
    toast.dismiss();
  };
  const exportData = () => {
    setIsLoading(true);
    var payload;
    if (selected === "Custom Date Range") {
      payload = {
        created_at_start: formatCurrentDate(new Date(dateState[0].startDate)),
        created_at_end: formatCurrentDate(new Date(dateState[0].endDate)),
      };
    } else {
      payload = {
        created_at_start: getDateRange(selected).startDate,
        created_at_end: getDateRange(selected).endDate,
      };
    }

    if (payload.created_at_end && payload.created_at_start) {
      exportPayoutData({ data: base64(payload) })
        .then((response) => {
          if (response.status === 1) {
            const exportData = response.data.map((ele) => {
              return {
                transaction_request_number: ele.unique_request_number,
                amount: ele.amount,
                beneficiary_bank_name: ele.beneficiary_bank_name,
                beneficiary_account_number: ele.beneficiary_account_number,
                transfer_date: ele.transfer_date,
                beneficiary_name: ele.beneficiary_account_name,
                beneficiary_account_ifsc: ele.beneficiary_account_ifsc,
                beneficiary_upi_handle: ele.beneficiary_upi_handle,
                payment_mode: ele.payment_mode,
                source_virtual_account: ele.source_virtual_account,
                status: ele.status,
                failure_reason: ele.failure_reason,
                unique_transaction_reference: ele.unique_transaction_reference,
              };
            });
            if (exportData.length > 0) {
              downloadFile(
                exportData,
                "payout",
                selectFileFormate.toLocaleLowerCase()
              );
              handleClose();
              toast.custom(
                <SingleErrorMessage
                  data={"SuccessFully Exported Payout Details"}
                  error={false}
                  bColor="#007500"
                />
              );
              setIsLoading(false);
            } else {
              toast.custom(
                <SingleErrorMessage
                  data={"data is not present"}
                  error={true}
                  bColor="#D92D20"
                />
              );
              setIsLoading(false);
            }
          } else {
            toast.error(response.message);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  const handleApply = () => {
    const startDate = new Date(dateState[0].startDate);
    const endDate = new Date(dateState[0].endDate);
    setSelectDateRange(
      `custom ${startDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })} - ${endDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}`
    );

    setShowCalender(!showCalender);
  };

  const handleExportData = () => {
    exportData();
    // setOpenExport(true);
  };
  const handleCloseCalender = () => {
    setShowCalender(!showCalender);
  };

  function compareDates(startDateStr, endDateStr) {
    let startDate = new Date(startDateStr);
    let endDate = new Date(endDateStr);
    let currentDate = new Date();
    if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
      if (startDate < endDate) {
        return true;
      } else if (startDate.getTime() === endDate.getTime()) {
        let currentDate = new Date();
        return !isSameDate(startDate, currentDate);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function isSameDate(date1, date2) {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  let startDateStr = dateState[0].startDate;
  let endDateStr = dateState[0].endDate;

  let datesComparisonResult = compareDates(startDateStr, endDateStr);

  useEffect(() => {
    compareDates();
  }, [dateState]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose("");
        }
      }}>
      <div
        className={`${
          showCalender ? "modal-dialog opencelender" : "modal-dialog"
        }`}
        style={style}>
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => handleClose()}
          />
          <div className="modal-body">
            <>
              <div className="expert_payout-box">
                <div className="expert_payout-head">
                  <div className="imgHeader_img">
                    <img
                      className="_ExportIcon"
                      src={ExportIcom}
                      alt="Export icon"
                    />
                  </div>
                  <div className="imgUpload_text">
                    <div className="imgUpload_ttl expert_payout_ttl">
                      Export Payout Details
                    </div>
                    <div className="imgUpload_desc">
                      You can export your data in .CSV and .XLS format.{" "}
                    </div>
                  </div>
                </div>
                <div className="expert_payout-content">
                  <div className="expert_payout-daterange mt-4 mb-1">
                    <div
                      className={`transaction_id justify-content-between ${
                        toggle ? "active" : ""
                      }`}
                      onClick={handleToggle}
                      ref={select}>
                      {selected && selectDateRange
                        ? selectDateRange
                        : selected !== ""
                        ? selected
                        : "Select Date Range"}
                      <img
                        src={transArrow}
                        alt=""
                        className="transArrow"
                      />
                      <div className="transDrop">
                        <div
                          className={`transDrop-item w-100 ${
                            selected === "Today" ? "active" : ""
                          }`}
                          onClick={() => {
                            handleSelect("Today");
                          }}>
                          Today{" "}
                          <img
                            className="checkTichImg"
                            src={check_tick}
                            alt="tick"
                          />
                        </div>
                        <div
                          className={`transDrop-item w-100 ${
                            selected === "Yesterday" ? "active" : ""
                          }`}
                          onClick={() => {
                            handleSelect("Yesterday");
                          }}>
                          Yesterday
                          <img
                            className="checkTichImg"
                            src={check_tick}
                            alt="tick"
                          />
                        </div>
                        <div
                          className={`transDrop-item w-100 ${
                            selected === "This Week" ? "active" : ""
                          }`}
                          onClick={() => {
                            handleSelect("This Week");
                          }}>
                          This Week
                          <img
                            className="checkTichImg"
                            src={check_tick}
                            alt="tick"
                          />
                        </div>
                        <div
                          className={`transDrop-item w-100 ${
                            selected === "This Month" ? "active" : ""
                          }`}
                          onClick={() => {
                            handleSelect("This Month");
                          }}>
                          This Month
                          <img
                            className="checkTichImg"
                            src={check_tick}
                            alt="tick"
                          />
                        </div>
                        <div
                          className={`transDrop-item w-100 ${
                            selected === "Custom Date Range" ? "active" : ""
                          }`}
                          onClick={() => {
                            handleSelect("Custom Date Range");
                          }}>
                          Custom Date Range
                          <img
                            className="checkTichImg"
                            src={check_tick}
                            alt="tick"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {showCalender ? (
                    <div className=" d-flex justify-content-center expDateRng-box">
                      <div style={{ width: "330px" }}>
                        <div className="dateRange">
                          <DateRange
                            style={{ display: "block" }}
                            onChange={(item) => setDateState([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={dateState}
                            maxDate={new Date()}
                            editableDateInputs={false}
                          />
                        </div>
                        <div>
                          <div className="benyf_btn">
                            <button
                              className="beny_left-btn "
                              onClick={handleCloseCalender}>
                              Close
                            </button>
                            <button
                              className={`beny_right-btn newClass ${
                                datesComparisonResult ? "active" : ""
                              }`}
                              onClick={handleApply}>
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="payment_mode border-0">
                    <div className="payment_title">
                      Select Payment Mode<span>*</span>
                    </div>
                    <div className="payment_cards">
                      <div
                        className={`payment_card ${
                          selectFileFormate === "CSV" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleFileFormate("CSV");
                        }}>
                        <div className="payment_cardImg">
                          <img
                            src={csvIcon}
                            alt=""
                            className="payCardImg"
                          />
                        </div>
                        <div className="payment_card_text">CSV</div>
                        <div className="paymt_crd_check">
                          {" "}
                          <img
                            src={check_checked}
                            alt=""
                          />
                        </div>
                        <div className="paymt_crd_check2">
                          <img
                            src={check_empt}
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        className={`payment_card ${
                          selectFileFormate === "xlsx" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleFileFormate("xlsx");
                        }}>
                        <div className="payment_cardImg">
                          <img
                            src={csvIcon}
                            alt=""
                            className="payCardImg"
                          />
                        </div>
                        <div className="payment_card_text">XLS</div>
                        <div className="paymt_crd_check">
                          {" "}
                          <img
                            src={check_checked}
                            alt=""
                          />
                        </div>
                        <div className="paymt_crd_check2">
                          <img
                            src={check_empt}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="benyf_btn mt-4">
                      <div
                        className="beny_left-btn"
                        onClick={() => {
                          handleClose("");
                        }}>
                        {/* <img
                          src="/assets/images/x-close.svg"
                          alt=""
                          class="close"
                        /> */}
                        Cancel
                      </div>
                      <button
                        className="beny_right-btn1"
                        disabled={
                          (selected === "Custom Date Range"
                            ? !selectFileFormate
                            : !(selected && selectFileFormate)) || isLoading
                        }
                        onClick={handleExportData}>
                        {isLoading ? <CircularProgress size={20} /> : "Confirm"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </Modal>
  );
};
