import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/style.css";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { getFarmerBidDetails } from "../../action/warehouse";

export const DetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bidDetails, setBidDetails] = useState();

  const fetchDetails = async () => {
    const response = await getFarmerBidDetails(id);
    if (response.status) {
      setBidDetails(response.data[0]);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [id]);
  return (
    <div className="payout_data-content request_detail">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  {
                    name: "Bid List",
                    link: `/farmerBid/${bidDetails?.bidDetails?._id}`,
                  },
                  { name: "Request Details", link: `/farmerBid/details/${id}` },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="payout_data-content mb-3 user_detail">
        <h4>User Details</h4>
        <div>
          <span>Name : </span> {bidDetails?.userDetails?.name}
        </div>
        <div>
          <span>Mobile : </span> {bidDetails?.userDetails?.mobile}
        </div>
        <div>
          <span>Address : </span> {bidDetails?.location?.address}
        </div>
        <div>
          <span>Email : </span> {bidDetails?.userDetails?.email}
        </div>
      </div>
      <div className="payout_data-content bid_detail">
        <h4>Bid Details</h4>
        <div>
          <span>Product : </span> {bidDetails?.product}
        </div>
        <div>
          <span>Variety : </span> {bidDetails?.variety}
        </div>
        <div>
          <span>Quantity : </span> {bidDetails?.quantity} {bidDetails?.unit}
        </div>
        <div>
          <span>Delivery Address : </span> {bidDetails?.location?.address}
        </div>
        <div>
          <span>Grade : </span> {bidDetails?.grade}
        </div>
        <div>
          {bidDetails?.pics &&
            bidDetails?.pics.map((url) => {
              return (
                <img
                  src={process.env.REACT_APP_S3_URL + "/" + url}
                  height={100}
                  width={100}
                  alt="product"
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
