import "../styles/style.css";
import "../styles/payout.css";
import "../styles/pagination.css";
import emailIcon from "../assets/images/email_icon.svg";
import { SlickSlider } from "../pages/slider";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { login, sendOTP } from "../action/auth";
import logo from "../assets/images/gaon_logo.svg";
import contLeft from "../assets/images/login-left2.png";
import { useNavigate } from "react-router-dom";
import { AUTH_USER } from "../action/types";
import { toast } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import { SingleErrorMessage } from "./singleErrorMessage";

export function Login() {
  const isAuthenticated = useSelector((state) => state.auth.authenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [otpScreen, setOtpScreen] = useState(false);

  const loginForm = useFormik({
    initialValues: {
      mobile: "",
      otp: "",
    },
  });

  const handleLogin = async () => {
    setIsLoading(true);
    const response = await dispatch(
      login(loginForm.values.mobile, loginForm.values.otp)
    );
    if (response.status === 1) {
      localStorage.setItem("role", response?.data?.role);

      toast.custom(
        <SingleErrorMessage
          data={"Login Successfully"}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
      navigate("/");
      setIsLoading(false);
    } else {
      console.log("res ", response.message);
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );

      navigate("/login");
      setIsLoading(false);
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const sendOtp = async () => {
    const payload = {
      mobile: loginForm.values.mobile,
    };
    const response = await sendOTP(payload);
    if (response.status) {
      toast.success("OTP sent");
      setOtpScreen(true);
    } else {
      toast.error(response.message);
      // alert("Mobile Not valid");
    }
  };

  return (
    <div className="login_page">
      <div className="login_content">
        <div className="login_left">
          <div className="login_left-content">
            {/* <div className="slider-box">
              <img
                src={logImg}
                alt=""
              />
            </div> */}
            <div className="login_logo-box">
              <div className="sidelogo">
                <img src={logo} alt="" className="login_logo" />
              </div>
            </div>
            <img src={contLeft} alt="" className="leftCont-img" />
          </div>
        </div>
        <div className="login_right">
          <div className="login_right-content">
            <form onSubmit={loginForm.handleSubmit}>
              <div className="login_inner-content">
                <div className="login">Welcome Back, Login!</div>
                {/* <div className="login_desc">
                    Your payouts are just a click away!
                  </div> */}
                {!otpScreen ? (
                  <div className="input-box">
                    <div className="input-label label_text">Mobile</div>
                    <div className="input_field">
                      <input
                        type="text"
                        className="input input_text"
                        id="mobile"
                        placeholder="Enter your registered mobile"
                        value={loginForm.values.mobile}
                        onChange={loginForm.handleChange}
                      />
                    </div>
                    {loginForm.touched.email && loginForm.errors.email && (
                      <div className="danger">{loginForm.errors.email}</div>
                    )}
                  </div>
                ) : (
                  <div className="input-box">
                    <div className="input-label label_text">
                      Enter OTP<span>*</span>
                    </div>
                    <div className="input_field">
                      <input
                        type="text"
                        className="input input_text"
                        placeholder="Enter your OTP"
                        value={loginForm.values.otp}
                        onChange={loginForm.handleChange}
                        id="otp"
                      />
                    </div>
                    {loginForm.touched.otp && loginForm.errors.otp && (
                      <div className="danger">{loginForm.errors.otp}</div>
                    )}
                  </div>
                )}
                <div className="">
                  {/* <button
                      className="btn w-100 primary"
                      style={{
                        border: "1px solid grey",
                        borderRadius: "0.8rem",
                        padding: "0.5rem",
                        position: "relative",
                        zIndex: "1",
                      }}
                      disabled={
                        !loginForm.isValid || !loginForm.dirty || isLoading
                      }
                      type="submit"> */}
                  {otpScreen ? (
                    <button
                      className="submit-btn1 txt_20"
                      // disabled={
                      //   !loginForm.isValid || !loginForm.dirty || isLoading
                      // }
                      type="submit"
                      onClick={handleLogin}
                    >
                      Submit{" "}
                      <span>
                        {isLoading ? (
                          <div className="loader_box">
                            <CircularProgress
                              size={50}
                              style={{
                                color: "#e5dada",
                                // position: "absolute",
                                // top: "",
                                // left: "50%",
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </span>
                    </button>
                  ) : (
                    <button
                      className="submit-btn1 txt_20"
                      onClick={sendOtp}
                      type="button"
                      disabled={loginForm.values.mobile.length !== 10}
                    >
                      Send OTP{" "}
                    </button>
                  )}
                </div>
                <div className="sign_up-boxL">
                  <div className="sign_up-line">
                    {/* Don’t have an account? <Link to="/signup">Sign up</Link> */}
                    Don’t have an account Yet?
                    <span className="sign_span">
                      {" "}
                      mail to <Link className="help_link">help@thegaon.co</Link>
                    </span>
                  </div>
                </div>
              </div>
            </form>

            <div className="login_right-footer">
              <div className="login_footer-left">copyright@thegaon2024</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
