import React, { useState, useEffect } from "react";
import plusWhite from "../../assets/images/plus-white.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import { toast } from "react-hot-toast";
import trashIcon from "../../assets/images/trash-03.svg";
import Button from "../../components/bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useFormik } from "formik";
import Pagination from "../../customComponents/pagination/paginationView";
import {
  addCategoryData,
  deleteCategoryData,
  getCategoryData,
  getParentId,
} from "../../action/category";
import Select from "../../components/bootstrap/forms/Select";
import Option from "../../components/bootstrap/Option";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
import { Modal } from "react-bootstrap";
import { SingleErrorMessage } from "../singleErrorMessage";

const CategoryListPage = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalData, setTotalData] = useState(null);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const [languages] = useState([
    { name: "English", shortCode: "en" },
    { name: "Hindi", shortCode: "hi" },
  ]);

  const getCategoryPage = async (page) => {
    try {
      setIsLoading(true);
      const response = await getCategoryData(page, pageSize); // Adjust 10 to the desired number of items per page
      if (response.status) {
        setCategoryList(response.data.list);
        setTotalData(response.data.count);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  useEffect(() => {
    getCategoryPage(pageNo);
  }, [pageNo, pageSize]);

  const handleClose = () => {
    setShow(false);
    addCategoryFormik.handleReset();
  };
  const handleShow = () => setShow(true);

  const addCategoryFormik = useFormik({
    initialValues: {
      name: { en: "" },
      parentId: "",
    },
    onSubmit: async (values, { resetForm }) => {
      console.log("values", values);
      setIsLoading(true);
      try {
        const res = await addCategoryData(values);
        if (res.status) {
          resetForm();
          setShow(false);
          // showNotification("Category", res.message, "success");
          getCategoryPage(1);
          handleClose();
          setIsLoading(false);
        } else {
          // showNotification("Category", res.message, "danger");
        }
      } catch (error) {
        console.error("Failed to add category", error);
      }
    },
  });
  const fetchParentId = async () => {
    const res = await getParentId();
    if (res.status) {
      setParentData(res.data);
    }
  };

  useEffect(() => {
    fetchParentId();
  }, []);

  const handleLanguageChange = (event, index) => {
    const selectedLanguage = event.target.value;
    const updatedNames = { ...addCategoryFormik.values.name };
    const currentLanguageKey = Object.keys(updatedNames)[index];
    updatedNames[selectedLanguage] = updatedNames[currentLanguageKey] || "";
    delete updatedNames[currentLanguageKey];
    addCategoryFormik.setFieldValue("name", updatedNames);
  };

  const handleAddMore = () => {
    const updatedNames = { ...addCategoryFormik.values.name };
    const newKey = languages.find(
      (lang) => !Object.keys(updatedNames).includes(lang.shortCode)
    )?.shortCode;
    if (newKey) {
      updatedNames[newKey] = "";
      addCategoryFormik.setFieldValue("name", updatedNames);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await deleteCategoryData(selectedItem);
      if (res.status) {
        toast.custom(
          <SingleErrorMessage
            data={res.message}
            error={false}
            bColor="#175FC9"
          />
        );
        getCategoryPage(pageNo);
        setShowDeleteModel(false);
      }
    } catch (error) {
      //console.error("Failed to delete category", error);
    }
  };
  const availableLanguages = (currentValue) => {
    return languages.filter(
      (language) =>
        !Object.keys(addCategoryFormik.values.name).includes(
          language.shortCode
        ) || language.shortCode === currentValue
    );
  };
  return (
    <div className="payout_data-content" style={{ height: '100%' }}>
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb paths={[{ name: "Products" }]} />
            </div>
          </div>
          <Button color="info" tag="a" onClick={handleShow}>
            Create New
            <img src={plusWhite} alt="" className="payout_down" />
          </Button>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <div className="scroll_v">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="4">
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {categoryList?.length > 0 ? (
                        categoryList?.map((item) => (
                          <tr key={item._id}>
                            <td>{item?.name?.en || item?.name?.hi}</td>
                            <td className="flex_btn">
                              <img
                                src={trashIcon}
                                onClick={() => {
                                  setSelectedItem(item?._id);
                                  setShowDeleteModel(true);
                                }}
                              />
                              {/* <Button
                              className="btn-dark"
                              color="dark"
                              isLight
                              onClick={() => {
                                console.log("JDF", item._id);
                               
                              }}
                            >
                              Delete
                            </Button> */}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </TabPanel>
        </Tabs>

        {categoryList.length > 0 && (
          <>
            <Pagination
              currentPage={pageNo}
              totalCount={totalData}
              pageSize={pageSize}
              onPageChange={(page) => setPageNo(page)}
              changePageSize={(page) => {
                setPageSize(page);
                setPageNo(1);
              }}
            />
          </>
        )}

        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Add New Product</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <div className="input-label">Category</div>
              <Select
                name="parentId"
                value={addCategoryFormik.values.parentId}
                onChange={addCategoryFormik.handleChange}
              >
                <Option value="">Select a parent category</Option>
                {parentData.map((item) => (
                  <Option key={item._id} value={item._id}>
                    {item.name.en}
                  </Option>
                ))}
              </Select>
              <form onSubmit={addCategoryFormik.handleSubmit}>
                {Object.keys(addCategoryFormik.values.name).map(
                  (languageKey, index) => (
                    <div className="row" key={index}>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <div className="input-label">Language</div>
                          <div className="input_field">
                            <Select
                              ariaLabel="Role"
                              onChange={(event) =>
                                handleLanguageChange(event, index)
                              }
                              value={languageKey}
                            >
                              {availableLanguages(languageKey).map((item) => (
                                <Option
                                  key={item.shortCode}
                                  value={item.shortCode}
                                >
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="input-box">
                        <div className="input-label">Category Name</div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            name={`name.${languageKey}`}
                            placeholder="Enter Name"
                            value={
                              addCategoryFormik.values.name[languageKey] || ""
                            }
                            onChange={addCategoryFormik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
                <div className="addBuyer_submit-btn">
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={handleAddMore}
                  >
                    Add More
                  </button>
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          show={showDeleteModel}
          onHide={() => {
            setShowDeleteModel(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this product?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowDeleteModel(false);
              }}
            >
              Close
            </Button>
            <Button
              className="btn-dark"
              variant="primary"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default CategoryListPage;
