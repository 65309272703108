import {
  createBrowserRouter,
  createRoutesFromElements,
  BrowserRouter,
  Link,
  Route,
  Routes,
  RouterProvider,
} from "react-router-dom";

import { Login } from "../pages/login";
import { SignUp } from "../pages/signUp";
import { DashboardLayout } from "../pages/dashboardLayout";
import { Payout } from "../pages/payout";
import { ProtectedRoute } from "../routes/protectedRoutes";
import { PageNotFound } from "../pages/404Page";
import { ComingSoonPage } from "../pages/comingSoon";
import { UpiHome } from "../pages/upiMandate/upiHome";
import { Report } from "../pages/reports/report";
import { BuyerRequestList } from "../pages/Buyer/buyerRequestList";
import { WarehouseBidToBuyerList } from "../pages/Buyer/warehouseBidList";
import { WarehouseBidList } from "../pages/warehouse/bidWarehouseList";
import { RequestToFarmerList } from "../pages/warehouse/requestToFarmer";
import { FarmerBidList } from "../pages/warehouse/bidFarmerList";
import { AddBuyerRequest } from "../pages/Buyer/addBuyerRequest";
import Gaon from "../pages/gaon/Gaon";
import ShowGaon from "../pages/gaon/ShowGaon";
import UserList from "../pages/users/UserList";
import GetUnitList from "../pages/unitLIst/GetUnitList";
import CategoryListPage from "../pages/category/CategoryListPage";
import EditeGaon from "../pages/gaon/EditeGaon";
import { Dashboard } from "../pages/Dashboard/dashboard";
import { DetailsPage } from "../pages/warehouse/farmerBidDetails";
import ModuleList from "../pages/module/moduleList";
import ModuleAdd from "../pages/module/moduleAdd";
import ModuleEdit from "../pages/module/moduleEdit";
import ContestList from "../pages/contest/contestList";
import ContestAdd from "../pages/contest/contestAdd";
import ContestEdit from "../pages/contest/contestEdit";
const role = localStorage.getItem("role");

const route = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="login" element={<Login />} />
      {/* <Route path="signup" element={<SignUp />} /> */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<Dashboard />} />
        <Route path="/gaon" element={<Gaon />} />
        <Route path="/gaon/edit/:id" element={<EditeGaon />} />
        <Route path="/gaon/details/:id" element={<ShowGaon />} />
        <Route path="/userList" element={<UserList />} />
        <Route path="/unitList" element={<GetUnitList />} />
        <Route path="/product/list" element={<CategoryListPage />} />

        <Route path="/buyerReqest/list" element={<BuyerRequestList />} />
        <Route path="/bidWarehouse" element={<WarehouseBidList />} />
        <Route
          path="/warehouseRequest/list"
          element={<RequestToFarmerList />}
        />
        <Route path="/farmerBid/:id" element={<FarmerBidList />} />
        <Route
          path="/buyerRequest/bid/list/:id"
          element={<WarehouseBidToBuyerList />}
        />
        <Route path="/buyerRequest/add" element={<AddBuyerRequest />} />
        <Route path="/farmerBid/details/:id" element={<DetailsPage />} />

        <Route path="/module/list" element={<ModuleList />} />
        <Route path="/module/add" element={<ModuleAdd />} />
        <Route path="/module/edit/:id" element={<ModuleEdit />} />

        <Route path="/contest/list" element={<ContestList />} />
        <Route path="/contest/add" element={<ContestAdd />} />
        <Route path="/contest/edit/:id" element={<ContestEdit />} />

        <Route path="/" element={<PageNotFound />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

export default route;
