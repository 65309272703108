import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import moment from "moment";
import { fetchBuyerRequestList } from "../../action/buyer";
import {
  addWarehouseBid,
  createWarehouseRequest,
  fetchWarehouseBids,
} from "../../action/warehouse";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import { getProducts, getunits } from "../../action/users";
import { useFormik } from "formik";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "../../components/bootstrap/Button";
import { handleKeyDown } from "../../helper/helper";
import { Loader } from "../loader";
import Pagination from "../../customComponents/pagination/paginationView";

export const WarehouseBidList = () => {
  const [buyerReuqstList, setBuyerReuqstList] = useState([]);
  const [bidList, setBidList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationValue, setPaginationValue] = useState(10);
  const [id, setId] = useState("");
  const [buyerReqId, setBuyerReqId] = useState("");
  const [status, setStatus] = useState(0);
  const [show, setShow] = useState(false);
  const [showBidCanvas, setShowBidCanvas] = useState(false);
  const [units, setUnits] = useState([]);
  const [products, setproducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleClose = () => {
    setShow(false);
    requestToFarmerFormik.handleReset();
  };
  const handleShow = () => setShow(true);

  const fetchBuyerList = async () => {
    setIsLoading(true);

    const response = await fetchBuyerRequestList(
      currentPage,
      paginationValue,
      0
    );
    if (response.status) {
      setBuyerReuqstList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const fetchWarehouseBidList = async () => {
    setIsLoading(true);

    const response = await fetchWarehouseBids(
      currentPage,
      paginationValue,
      status
    );
    if (response.status) {
      setBidList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const requestToFarmerFormik = useFormik({
    initialValues: {
      // productId: "",
      // quantity: 0,
      // unitId: "",
      price: "",
      deliveryDate: "",
      buyerReqId: "",
    },
    onSubmit: async (values) => {
      values.deliveryDate = moment(values.deliveryDate).format("YYYY-MM-DD");
      values.buyerReqId = buyerReqId;
      // console.log(values);
      const response = await createWarehouseRequest(values);
      if (response.status) {
        toast.custom(
          <SingleErrorMessage
            data={"Requested to farmer successfully"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        // navigate("/warehouseRequest");
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
      handleClose();

      requestToFarmerFormik.setFieldValue("price", "");
      requestToFarmerFormik.setFieldValue("deliveryDate", "");
    },
  });

  const addBidFormik = useFormik({
    initialValues: {
      bidId: "",
      price: 0,
      deliveryDate: "",
    },
    onSubmit: async (values) => {
      values.bidId = id;
      // console.log(values);
      const response = await addWarehouseBid(values);
      if (response.status) {
        toast.custom(
          <SingleErrorMessage
            data={"Bid applied successfuly"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        setShowBidCanvas(false);
        // navigate("/bidWarehouse");
      } else {
        toast.custom(
          <SingleErrorMessage
            data={"You have already bid"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        setShowBidCanvas(false);
      }
      addBidFormik.handleReset();
    },
  });

  useEffect(() => {
    fetchBuyerList();
    fetchProducts();
    fetchUnits();
  }, [currentPage, paginationValue]);

  useEffect(() => {
    fetchWarehouseBidList();
  }, [currentPage, paginationValue, status]);

  const fetchUnits = async () => {
    const response = await getunits();
    if (response.status) {
      setUnits(response.data);
    } else {
    }
  };

  const fetchProducts = async () => {
    const response = await getProducts();
    if (response.status) {
      setproducts(response.data);
    } else {
    }
  };
  const onCloseToaste = () => {
    toast.dismiss();
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              Buyer Request List
              {/* <div className="hits">1321 Hits</div> */}
            </div>
            {/* <div className="payout_data-desc">
              Keep track of your daily payouts.
            </div> */}
          </div>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabList>
            <Tab
              onClick={() => {
                fetchBuyerList();
                setCurrentPage(1);
                setPaginationValue(10);
              }}
            >
              New Requests
              {/* <div className="paymentHits">{totalRecords}</div> */}
            </Tab>
            <Tab
              onClick={() => {
                setStatus(4);
                setCurrentPage(1);
                setPaginationValue(10);
              }}
            >
              Applied Requests
              {/* <div className="paymentHits">{totalRecords}</div> */}
            </Tab>
            <Tab
              onClick={() => {
                setStatus(1);
                setCurrentPage(1);
                setPaginationValue(10);
              }}
            >
              {" "}
              Confirmed Requests
              {/* <div className="paymentHits">{totalRecords}</div> */}
            </Tab>
            <Tab
              onClick={() => {
                setStatus(2);
                setCurrentPage(1);
                setPaginationValue(10);
              }}
            >
              Completed Request
              {/* <div className="paymentHits">{totalRecords}</div> */}
            </Tab>
          </TabList>
          <TabPanel>
            <div className="scroll_v">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Buyer Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Address</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Action </div>
                    </th>
                  </tr>
                </thead>

                {/* <tbody> */}

                {isLoading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : buyerReuqstList.length > 0 ? (
                  buyerReuqstList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="noWrap">{item.orderId}</td>
                        <td className="phone_number noWrap">
                          {moment(item?.createdAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="Status">{item?.userDetails?.name}</td>
                        <td className="Status">
                          {item?.location}
                          {/* <div className="noData"></div> */}
                        </td>
                        <td className="table_amount">{item?.product}</td>
                        <td className="table_amount text-center">
                          {item?.veriety ?? "-"}
                        </td>
                        <td className="text-center">{item?.grade}</td>
                        <td className="Status">
                          <div>{item?.price ?? 0}</div>
                        </td>
                        <td className="Status">
                          <div>{item?.quantity + " " + item?.unit}</div>
                        </td>
                        <td className="noWrap">
                          {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          {/* <div className="action_payment"> */}
                          <Button
                            color="dark"
                            className="noWrap"
                            isLight="btn btn-primary"
                            tag="a"
                            onClick={() => {
                              setId(item._id);
                              setShowBidCanvas(true);
                            }}
                          >
                            Apply Bid
                          </Button>
                          {/* </div> */}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="10" className="text-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
                {/* </tbody> */}
              </table>{" "}
            </div>
          </TabPanel>
          <TabPanel>
            {/* <div className="payout_table-box upi-mandate_table-box"> */}
            <div className="scroll_v">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Buyer Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Address</div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Date</div>
                    </th>
                  </tr>
                </thead>

                {isLoading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : bidList.length > 0 ? (
                  bidList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td>{item?.bidDetails?.orderId}</td>
                        <td className="phone_number">{item?.buyer?.name}</td>
                        <td className="Status">
                          {item?.bidDetails?.location?.address}
                          {/* {item?.location} */}
                          {/* <div className="noData"></div> */}
                        </td>
                        <td className="table_amount">{item?.product}</td>
                        <td className="table_amount text-center">
                          {item?.veriety ?? "-"}
                        </td>
                        <td className="text-center">{item?.grade}</td>

                        <td className="Status">
                          <div>{item?.price ?? 0}</div>
                        </td>
                        <td className="Status">
                          <div>{item?.quantity + " " + item?.unit}</div>
                        </td>
                        <td>
                          {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="10" className="text-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </table>{" "}
            </div>
            {/* </div> */}
          </TabPanel>
          <TabPanel>
            <div className="scroll_v">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Buyer Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Address</div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Required Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">
                        Accepted Farmers Quantity{" "}
                      </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Action </div>
                    </th>
                  </tr>
                </thead>

                {isLoading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : bidList.length > 0 ? (
                  bidList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="noWrap">{item?.bidDetails?.orderId}</td>
                        <td className="phone_number">{item?.buyer?.name}</td>
                        <td className="Status address_td">
                          {item?.bidDetails?.location?.address}
                          {/* {item?.location} */}
                          {/* <div className="noData"></div> */}
                        </td>
                        <td className="table_amount">{item?.product}</td>
                        <td className="table_amount text-center">
                          {item?.veriety ?? "-"}
                        </td>
                        <td className="text-center">{item?.grade}</td>

                        <td className="Status">
                          <div>{item?.price ?? 0}</div>
                        </td>
                        <td className="Status noWrap">
                          <div>{item?.quantity + " " + item?.unit}</div>
                        </td>
                        <td className="Status noWrap">
                          <div>
                            {item?.allocateQty
                              ? item?.allocateQty + " " + item?.unit
                              : 0}
                          </div>
                        </td>
                        <td className="noWrap">
                          {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          <div className="action_payment">
                            <Button
                              className="noWrap"
                              color="dark"
                              isLight="btn btn-primary"
                              tag="a"
                              onClick={() => {
                                setBuyerReqId(item?.bidDetails?._id);
                                handleShow();
                              }}
                            >
                              Request to Farmer
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="10" className="text-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </table>{" "}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="scroll_v">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Buyer Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Address</div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Date</div>
                    </th>
                  </tr>
                </thead>

                {isLoading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : bidList.length > 0 ? (
                  bidList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="noWrap">{item?.bidDetails?.orderId}</td>
                        <td className="phone_number">{item?.buyer?.name}</td>
                        <td className="Status">
                          {item?.bidDetails?.location?.address}
                          {/* {item?.location} */}
                          {/* <div className="noData"></div> */}
                        </td>
                        <td className="table_amount">{item?.product}</td>
                        <td className="table_amount text-center">
                          {item?.veriety ?? "-"}
                        </td>
                        <td className="text-center">{item?.grade}</td>

                        <td className="Status">
                          <div>{item?.price ?? 0}</div>
                        </td>
                        <td className="Status noWrap">
                          <div>{item?.quantity + " " + item?.unit}</div>
                        </td>
                        <td className="noWrap">
                          {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="10" className="text-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </table>{" "}
            </div>
          </TabPanel>
          <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={paginationValue}
            onPageChange={(page) => setCurrentPage(page)}
            changePageSize={(page) => {
              setPaginationValue(page);
              setCurrentPage(1);
            }}
          />
        </Tabs>

        {/* off canvas to create warehouse new request to farmer*/}
        <Offcanvas
          show={showBidCanvas}
          onHide={() => {
            setShowBidCanvas(false);
            addBidFormik.handleReset();
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Apply Bid</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form action="" onSubmit={addBidFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Price
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter price"
                          id="beneficiary"
                          name="price"
                          min="1"
                          max="1000000"
                          onChange={(e) => {
                            addBidFormik.setFieldValue("price", e.target.value);
                          }}
                          value={addBidFormik.values.price}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-box">
                    <div className="input-label">
                      Delivery Date
                      <span>*</span>
                    </div>
                    <div className="input_field">
                      <input
                        required
                        name="deliveryDate"
                        className="input"
                        type="date"
                        placeholder="select date"
                        value={addBidFormik.values.deliveryDate}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={addBidFormik.handleChange}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex_btn mt-3 ">
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {/* off canvas to create warehouse new request to farmer*/}
        <Offcanvas
          show={show}
          onHide={() => {
            handleClose();
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>New Request</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form action="" onSubmit={requestToFarmerFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Price
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter price"
                          id="beneficiary"
                          name="price"
                          min="1"
                          max="10000000"
                          onChange={(e) => {
                            requestToFarmerFormik.setFieldValue(
                              "price",
                              e.target.value
                            );
                          }}
                          value={requestToFarmerFormik.values.price}
                          onKeyDown={handleKeyDown}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-box">
                    <div className="input-label">
                      Delivery Date
                      <span>*</span>
                    </div>
                    <div className="input_field">
                      <input
                        className="input"
                        required
                        name="deliveryDate"
                        type="date"
                        placeholder="select date"
                        value={requestToFarmerFormik.values.deliveryDate}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={requestToFarmerFormik.handleChange}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex_btn mt-3 ">
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};
