import { requestWithToken } from "../helper/helper";

const fetchUsersList = async (currentPage, perPage, search, sort, order) => {
  try {
    const queryParams = [];

    if (currentPage) queryParams.push(`pageNo=${currentPage}`);
    if (perPage) queryParams.push(`pageSize=${perPage}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/admin/userList${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchAdmin = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/roles`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const editAdmin = async (data) => {
  try {
    const response = await requestWithToken("PUT", `/admin/userRole`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
export { fetchAdmin, editAdmin, fetchUsersList };
