import React, { useState, useEffect } from "react";
import Pagination from "../../customComponents/pagination/paginationView";
import searchIcon from "../../assets/images/search-lg.svg";
import filerIcon from "../../assets/images/Up_Down.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import { editAdmin, fetchAdmin, fetchUsersList } from "../../action/userApi";
import Select from "../../components/bootstrap/forms/Select";
import Option from "../../components/bootstrap/Option";
import { Button, Dropdown, Modal } from "react-bootstrap";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";

const UserList = () => {
  const [userList, setUserList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");

  const getAdmin = async () => {
    const res = await fetchAdmin();
    if (res.status) {
      setRoleData(res.data);
    }
  };

  useEffect(() => {
    getAdmin();
  }, []);

  const getUserData = async (searchValue = search) => {
    setIsLoading(true);
    const response = await fetchUsersList(
      pageNo,
      pageSize,
      searchValue,
      sort,
      order
    ); // Adjust 10 to the desired number of items per page
    if (response.status) {
      setUserList(response.data.data);
      setTotalData(response.data.count);
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    const resetSearchValue = ""; // Reset search value
    setSearch("");
    getUserData(resetSearchValue);
  };

  useEffect(() => {
    getUserData();
  }, [pageNo, pageSize, sort, order]);

  const handleUpdate = async () => {
    setIsLoading(true);
    const response = await editAdmin({
      userId: selectedUser,
      roleId: selectedRole,
    });
    if (response.status) {
      getUserData(pageNo); // Refresh the user list
    }
    setIsOpenModal(false);
    setIsLoading(false);
  };

  const handleRoleChange = (userId, roleId) => {
    setSelectedUser(userId);
    setSelectedRole(roleId);
    setIsOpenModal(true);
  };

  return (
    <div className="payout_data-content" style={{ height: '100%' }}>
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb paths={[{ name: "Users" }]} />
            </div>
          
          </div>
        </div>
      </div>
      <div className="row search_box">
             <div className="col-lg-3">
             <div className="input-box mt-0">
                <div className="input-label">Search</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Type any keyword"
                    id="beneficiary"
                    name="search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <div className="build_search">
                    <img src={searchIcon} alt="Search Icon" />
                  </div>
                </div>
              </div>
              <div className="submit_btn_grp flex_btn mt-3">
                <button
                  className="btn_submit btn btn-dark"
                  type="button"
                  onClick={() => {
                    handleReset();
                  }}
                  disabled={!search}
                >
                  Reset
                </button>
                <button
                  className={`btn btn-success btn_submit ${search ? "active" : ""}`}
                  type="submit"
                  disabled={!search}
                  onClick={() => {
                    getUserData();
                  }}
                >
                  Search
                </button>
              </div>
             </div>
            </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
           <div className="scroll_v">
           <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">
                      Name
                      <img
                        src={filerIcon}
                        onClick={() => {
                          setSort("name");
                          setOrder(order == 1 ? -1 : 1);
                        }}
                      />
                    </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Email</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Mobile No</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Place</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Role</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="4">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {userList?.length > 0 ? (
                      userList?.map((item) => (
                        <tr key={item?._id}>
                          <td>{item?.name}</td>
                          <td>{item?.email || "-"}</td>
                          <td>{item?.mobile}</td>
                          <td>{item?.gaon}</td>
                          <td>
                            <Select
                              size="sm"
                              ariaLabel="Role"
                              onChange={(e) =>
                                handleRoleChange(item._id, e.target.value)
                              }
                              value={item.role}
                            >
                              <Option>{item.role}</Option>
                              {roleData
                                .filter((data) => data.name !== item.role)
                                .map((i) => (
                                  <Option key={i._id} value={i._id}>
                                    {i.name}
                                  </Option>
                                ))}
                            </Select>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
           </div>
          </TabPanel>
        </Tabs>
        {userList.length > 0 && (
          <>
            <Pagination
              currentPage={pageNo}
              totalCount={totalData}
              pageSize={pageSize}
              onPageChange={(page) => setPageNo(page)}
              changePageSize={(page) => {
                setPageSize(page);
                setPageNo(1);
              }}
            />
          </>
        )}

        <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to change the role of this user?
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-dark" variant="secondary" onClick={() => setIsOpenModal(false)}>
              No
            </Button>
            <Button className=" btn-success" variant="primary" onClick={handleUpdate}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UserList;
