import React, { useState, useEffect } from "react";
import plusWhite from "../../assets/images/plus-white.svg";
import trashIcon from "../../assets/images/trash-03.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import Button from "../../components/bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  addUnitList,
  deleteUnit,
  fetchUnitList,
  getPagesList,
} from "../../action/unitList";
import { useFormik } from "formik";
import Select from "../../components/bootstrap/forms/Select";
import Option from "../../components/bootstrap/Option";
import Pagination from "../../customComponents/pagination/paginationView";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";

const GetUnitList = () => {
  const [unitRequestList, setUnitRequestList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [id, setId] = useState("");

  const getUnitList = async () => {
    setIsLoading(true);
    const response = await getPagesList(pageSize, pageNo);
    if (response.status) {
      setUnitRequestList(response.data.list);
      setTotalData(response.data.count);
      setIsLoading(false);
    }
  };

  const handleDeleteUnit = async () => {
    const response = await deleteUnit(id);
    if (response.status) {
      getUnitList();
      setShowDeleteModel(false);
      toast.custom(
        <SingleErrorMessage
          data={"Unit deleted Successfully"}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  useEffect(() => {
    getUnitList();
  }, [pageNo, pageSize]);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    addFormik.handleReset();
  };
  const handleShow = () => setShow(true);

  const [languages] = useState([
    { name: "English", shortCode: "en" },
    { name: "Hindi", shortCode: "hi" },
    { name: "Marathi", shortCode: "Mr" },
  ]);

  const addFormik = useFormik({
    initialValues: {
      name: { en: "" },
    },
    onSubmit: async (values, { resetForm }) => {
      const res = await addUnitList(values);
      if (res.status) {
        setShow(false);
        getUnitList();
        handleClose();
        resetForm();
        toast.custom(
          <SingleErrorMessage
            data={"Unit added successfully"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
      } else {
        toast.custom(
          <SingleErrorMessage
            data={res.message ?? res}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const handleLanguageChange = (event, index) => {
    const selectedLanguage = event.target.value;
    const updatedNames = { ...addFormik.values.name };
    const currentLanguageKey = Object.keys(updatedNames)[index];

    // Only update if the selected language is different from the current one
    if (currentLanguageKey !== selectedLanguage) {
      updatedNames[selectedLanguage] = updatedNames[currentLanguageKey] || "";
      delete updatedNames[currentLanguageKey];
      addFormik.setFieldValue("name", updatedNames);
    }
  };

  const handleAddMore = () => {
    const updatedNames = { ...addFormik.values.name };
    const newKey = languages.find(
      (lang) => !Object.keys(updatedNames).includes(lang.shortCode)
    )?.shortCode;

    if (newKey) {
      updatedNames[newKey] = "";
      addFormik.setFieldValue("name", updatedNames);
    }
  };
  const availableLanguages = (currentValue) => {
    return languages.filter(
      (language) =>
        !Object.keys(addFormik.values.name).includes(language.shortCode) ||
        language.shortCode === currentValue
    );
  };

  return (
    <div className="payout_data-content" style={{ height: '100%' }}>
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb paths={[{ name: "Units" }]} />
            </div>
          </div>
          <Button color="info" tag="a" onClick={handleShow}>
            Create New
            <img src={plusWhite} alt="" className="payout_down" />
          </Button>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Name</div>
                  </th>
                  <th>
                    <div className="payoutHead hndIcon">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {unitRequestList?.length > 0 ? (
                      unitRequestList?.map((item) => {
                        if (item.name.en || item.name.hi) {
                          return (
                            <tr key={item._id}>
                              <td>{item?.name?.en}</td>
                              <td>
                                <img
                                  src={trashIcon}
                                  onClick={() => {
                                    setId(item._id);
                                    setShowDeleteModel(true);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
        {unitRequestList.length > 0 ? (
          <Pagination
            currentPage={pageNo}
            totalCount={totalData}
            pageSize={pageSize}
            onPageChange={(page) => setPageNo(page)}
            changePageSize={(page) => {
              setPageSize(page);
              setPageNo(1);
            }}
          />
        ) : (
          <></>
        )}
        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Create New Unit</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form onSubmit={addFormik.handleSubmit}>
                {Object.keys(addFormik.values.name).map(
                  (languageKey, index) => (
                    <div className="row" key={index}>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <div className="input-label">Language</div>
                          <div className="input_field">
                            <Select
                              size="sm"
                              ariaLabel="Language"
                              onChange={(event) =>
                                handleLanguageChange(event, index)
                              }
                              value={languageKey}
                            >
                              {availableLanguages(languageKey).map((item) => (
                                <Option
                                  key={item.shortCode}
                                  value={item.shortCode}
                                >
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="input-box">
                        <div className="input-label">Unit Name</div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            name={`name.${languageKey}`}
                            placeholder="Enter Name"
                            value={addFormik.values.name[languageKey] || ""}
                            onChange={addFormik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
                <div className="addBuyer_submit-btn">
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={handleAddMore}
                  >
                    Add More
                  </button>
                  {"  "}
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          show={showDeleteModel}
          onHide={() => {
            setShowDeleteModel(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this unit?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowDeleteModel(false);
              }}
            >
              No
            </Button>
            <Button
              className="btn-dark"
              variant="primary"
              onClick={() => {
                handleDeleteUnit();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default GetUnitList;
