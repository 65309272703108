import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
import Icon from "../../components/icon/Icon";
import { createContest, getModuleList } from "../../action/contest";
import trashIcon from "../../assets/images/trash-03.svg";

const ContestAdd = () => {
  const navigate = useNavigate();
  const [moduleList, setModuleList] = useState([]);

  const addContestFormik = useFormik({
    initialValues: {
      title: { en: "" },
      moduleId: "",
      options: [{ title: { en: "" }, isCorrect: false }],
    },
    onSubmit: async (values) => {
      const res = await createContest(values);
      if (res.status) {
        navigate("/contest/list");
      }
    },
  });

  const getList = async () => {
    const res = await getModuleList(1, 10000);
    if (res.status) {
      setModuleList(res.data.details);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const addMoreOptions = () => {
    addContestFormik.setFieldValue("options", [
      ...addContestFormik.values.options,
      { title: { en: "" }, isCorrect: false },
    ]);
  };

  const addRemoveOptions = (mainIndex) => {
    addContestFormik.setFieldValue(
      "options",
      addContestFormik.values.options.filter(
        (value, index) => index !== mainIndex
      )
    );
  };

  return (
    <div className="payout_data-content">
      <div className="tab_box">
        <form onSubmit={addContestFormik.handleSubmit} className="m-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">Title</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    name="title.en"
                    placeholder="Enter title"
                    value={addContestFormik.values.title.en}
                    onChange={addContestFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">Module</div>
                <div className="input_field">
                  <select
                    type="text"
                    className="input"
                    name="moduleId"
                    value={addContestFormik?.values?.moduleId}
                    onChange={(e) => {
                      addContestFormik.setFieldValue(
                        "moduleId",
                        e.target.value
                      );
                    }}
                  >
                    <option value="">Select Module</option>
                    {moduleList.map((value) => {
                      return (
                        <option value={value._id}>{value.title.en}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            {addContestFormik.values.options?.map((value, index) => {
              return (
                <div className="col-lg-6">
                  <div className="input-box">
                    <div className="input-label">Title</div>
                    <div className="input_field">
                      <input
                        type="text"
                        className="input"
                        name={`options[${index}].title.en`}
                        placeholder="Enter title"
                        value={addContestFormik.values.options[index].title.en}
                        onChange={addContestFormik.handleChange}
                      />
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    className="input"
                    name={`options[${index}].isCorrect`}
                    placeholder="Enter title"
                    value={addContestFormik.values.options[index].isCorrect}
                    onChange={addContestFormik.handleChange}
                  />
                  <img
                    src={trashIcon}
                    onClick={() => {
                      addRemoveOptions(index);
                    }}
                  />
                </div>
              );
            })}
          </div>

          <div>
            <div>
              <div className="input-box ">
                <div className="flex_btn">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={addMoreOptions}
                  >
                    Add More Option
                  </button>
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContestAdd;
