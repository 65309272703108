const role = localStorage.getItem("role");

export const sidenavData = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "Buyer Request",
    icon: "home",
    path: "/buyerReqest/list",
  },
];

export const warehouse = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "Bid Warehouse",
    path: "/bidWarehouse",
    icon: "warehouse",
  },
  {
    title: "Request To Farmers",
    path: "/warehouseRequest/list",
    icon: "farm",
  },
];

export const adminMenu = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "Gaons",
    path: "/gaon",
    icon: "gaon",
  },
  {
    title: "Users",
    path: "/userList",
    icon: "users",
  },
  {
    title: "Units",
    path: "/unitList",
    icon: "units",
  },
  {
    title: "Products",
    path: "/product/list",
    icon: "products",
  },
  {
    title: "Module",
    path: "/module/list",
    icon: "joystick",
  },
  {
    title: "Contest",
    path: "/contest/list",
    icon: "joystick",
  },
];
