import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import { fetchWarehouseBidList, acceptBid } from "../../action/warehouse";
import Button from "../../components/bootstrap/Button";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Pagination from "../../customComponents/pagination/paginationView";

export const WarehouseBidToBuyerList = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [bidList, setBidList] = useState([]);
  const [date, setDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUpdateOpenModal, setIsUpdateOpenModal] = useState(false);
  const [warehouseId, setWarehouseId] = useState("");
  const [status, setStatus] = useState(0);

  const fetchList = async () => {
    const response = await fetchWarehouseBidList(
      currentPage,
      paginationValue,
      id
    );
    if (response.status) {
      setBidList(response.data.list);
      setTotalRecords(response.data.count);
    }
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, paginationValue]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUpdate = async () => {
    console.log("accepted");
    const payload = {
      warehouseBidId: warehouseId,
      status: 1,
    };
    const response = await acceptBid(id, payload);
    if (response.status) {
      fetchList();
      setShow(false);
      toast.custom(
        <singleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
      navigate("/buyerReqest/list");
    } else {
      toast.custom(
        <singleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb
                paths={[
                  { name: "Buyer Requests", link: "/buyerReqest/list" },
                  { name: "Bid List", link: "/buyerRequest/bid/list/:id" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box">
        <div className="scroll_v">
          <table className="payout_table upi-mandate_table">
            <thead>
              <tr className="tableHead">
                <th className="thHeader">
                  <div className="payoutHead hndIcon">Order ID</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead hndIcon">User Name</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Address</div>
                </th>

                <th className="thHeader">
                  <div className="payoutHead">Product</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Variety</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Price </div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Quantity </div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Delivery Date</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Action </div>
                </th>
              </tr>
            </thead>
            {bidList.length > 0 ? (
              bidList.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item?.bidDetails?.orderId}</td>
                    <td className="phone_number">{item?.userDetails?.name}</td>
                    <td className="Status">
                      {item?.location?.address}
                      {/* <div className="noData"></div> */}
                    </td>
                    <td className="table_amount noWrap">{item?.product}</td>
                    <td className="table_amount noWrap">
                      {item?.veriety ?? "-"}
                    </td>
                    <td className="Status">
                      <div>{item?.price ?? 0}</div>
                    </td>
                    <td className="Status noWrap">
                      <div>{item?.quantity + " " + item?.unit}</div>
                    </td>
                    <td className="noWrap">
                      {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                    </td>
                    <td>
                      <div className="action_payment">
                        <Button
                          color="info"
                          tag="a"
                          className="flex_btn ml-1 btn-success"
                          onClick={() => {
                            setWarehouseId(item?._id);
                            handleShow();
                          }}
                        >
                          Accept Bid
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <>
                <tr>
                  <td colSpan="10" className="text-center">
                    No data available
                  </td>
                </tr>
              </>
            )}
          </table>{" "}
        </div>
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords}
          pageSize={paginationValue}
          onPageChange={(page) => setCurrentPage(page)}
          changePageSize={(page) => {
            setPaginationValue(page);
            setCurrentPage(1);
          }}
        />
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Bid</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to accept bid?</Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-dark"
              variant="secondary"
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              className="btn-success"
              variant="primary"
              onClick={handleUpdate}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
