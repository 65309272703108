import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
import Icon from "../../components/icon/Icon";
import { createModule } from "../../action/contest";

const ModuleAdd = () => {
  const navigate = useNavigate();

  const [level] = useState([
    { name: "City", value: "CITY" },
    { name: "State", value: "STATE" },
    { name: "Gaon", value: "GAON" },
    { name: "Global", value: "GLOBAL" },
  ]);

  const [moduleType] = useState([
    { name: "Text", value: "TEXT" },
    { name: "Video", value: "VIDEO" },
    { name: "Image", value: "IMAGE" },
  ]);

  const [type] = useState([
    { name: "Generic", value: 0 },
    { name: "Live", value: 1 },
  ]);

  const addContestFormik = useFormik({
    initialValues: {
      title: { en: "" },
      level: "",
      levelIds: [],
      startDate: "",
      endDate: "",
      moduleType: "",
      assets: { src: "", w: 200, h: 200 },
      price: "",
      type: "",
    },
    onSubmit: async (values) => {
      const res = await createModule(values);
      if (res.status) {
        navigate("/module/list");
      }
    },
  });

  return (
    <div className="payout_data-content">
      <div className="tab_box">
        <form onSubmit={addContestFormik.handleSubmit} className="m-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">Title</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    name="title.en"
                    placeholder="Enter title"
                    value={addContestFormik.values.title.en}
                    onChange={addContestFormik.handleChange}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Price</div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    name="price"
                    placeholder="Enter price"
                    value={addContestFormik.values.price}
                    onChange={addContestFormik.handleChange}
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Module Type</div>
                <div className="input_field">
                  <select
                    type="text"
                    className="input"
                    placeholder="Level"
                    name="moduleType"
                    value={addContestFormik?.values?.moduleType}
                    onChange={(e) => {
                      addContestFormik.setFieldValue(
                        "moduleType",
                        e.target.value
                      );
                    }}
                  >
                    <option value="">Select Module</option>
                    {moduleType.map((value) => {
                      return <option value={value.value}>{value.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">End Date</div>
                <div className="input_field">
                  <input
                    type="date"
                    className="input"
                    name="endDate"
                    placeholder="End Date"
                    value={addContestFormik.values.endDate}
                    onChange={addContestFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">Level</div>
                <div className="input_field">
                  <select
                    type="text"
                    className="input"
                    placeholder="Level"
                    name="zone"
                    value={addContestFormik?.values?.level}
                    onChange={(e) => {
                      addContestFormik.setFieldValue("level", e.target.value);
                    }}
                  >
                    <option value="">Select Level</option>
                    {level.map((value) => {
                      return <option value={value.value}>{value.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Contest Type</div>
                <div className="input_field">
                  <select
                    type="text"
                    className="input"
                    placeholder="Level"
                    name="type"
                    value={addContestFormik?.values?.type}
                    onChange={(e) => {
                      addContestFormik.setFieldValue("type", e.target.value);
                    }}
                  >
                    <option value="">Select Type</option>
                    {type.map((value) => {
                      return <option value={value.value}>{value.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Start Date</div>
                <div className="input_field">
                  <input
                    type="date"
                    className="input"
                    name="startDate"
                    placeholder="Enter startDate"
                    value={addContestFormik.values.startDate}
                    onChange={addContestFormik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div className="input-box ">
                <div className="flex_btn">
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModuleAdd;
