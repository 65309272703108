import axios from "axios";
import { useNavigation } from "react-router-dom";

export const requestWithoutToken = async (method, url, body) => {
  let request;
  url = process.env.REACT_APP_API_ENDPOINT + url;

  switch (method) {
    case "GET":
      request = await axios.get(url);
      break;
    case "POST":
      request = await axios.post(url, body);
      break;
    default:
      break;
  }

  return request;
};

export const requestWithToken = async (method, url, body) => {
  let request;
  url = process.env.REACT_APP_API_ENDPOINT + url;

  const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
    },
  };

  switch (method) {
    case "GET":
      request = await axios.get(url, headers);
      break;
    case "POST":
      request = await axios.post(url, body, headers);
      break;
    case "PUT":
      request = await axios.put(url, body, headers);
      break;
    case "DELETE":
      request = await axios.delete(url, {
        data: body,
        headers: headers.headers,
      });
      break;

    default:
      break;
  }

  return request;
};

export const middleware = (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem("fstacSecretKey");
    localStorage.removeItem("fstacMid");
    localStorage.removeItem("fstacData");
    localStorage.removeItem("fstacAuthToken");
    window.location.href = "/login";
    return null;
  }
  return Promise.reject(error);
};

function checkLogout(request) {
  const storedLoginTime = localStorage.getItem("loginTime");
  if (storedLoginTime == undefined || storedLoginTime == null) {
    logout();
  }
  if (storedLoginTime) {
    const loginTime = new Date(parseInt(storedLoginTime)).getTime();
    const currentTime = new Date().getTime();
    // console.log(loginTime, currentTime);
    const diffMs = currentTime - loginTime;

    if (diffMs > 86400000) {
      logout();
      return null;
    }
  }
  return request;
}

function logout() {
  localStorage.removeItem("auth-token");
  window.location.href = "/login";
}

export const hasNotch = () => {
  /**
   * For storybook test
   */
  const storybook = window.location !== window.parent.location;
  const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
  const aspect = window.screen.width / window.screen.height;
  const aspectFrame = window.innerWidth / window.innerHeight;
  return (
    (iPhone && aspect.toFixed(3) === "0.462") ||
    (storybook && aspectFrame.toFixed(3) === "0.462")
  );
};

export const handleKeyDown = (event) => {
  if (event.type === "keydown") {
    if (
      (event.key >= "0" && event.key <= "9") ||
      event.key === "Backspace" ||
      event.key === "Delete" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "Home" ||
      event.key === "End" ||
      event.key === "Tab"
    ) {
      // Allow the key
    } else {
      // Prevent the key press for all other keys
      event.preventDefault();
    }
  }
};
